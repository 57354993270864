.countdown {
  display: flex;
  justify-content: space-between;
}

.time-component {
  padding: 0 5px;
  font-size: 1rem;
  width: 20%;
  text-align: center;
}
